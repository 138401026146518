import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"


const Layout = ({ pageTitle, children }) => {
    return (
        <>
            <div className="min-h-screen text-white font-Lato">
                <title>{pageTitle}</title>

                <nav className="py-10 mx-2 md:mx-8">
                        <StaticImage src="../images/ks-logo.png" alt="Karl Storz Endoskope" classname="w-24" width="200" />
                </nav>

                <main className="mx-2 md:mx-8">
                    {children}
                </main>

                
                <div className="mt-10">
                    
                </div>

            </div>
        </>
    )
}
export default Layout