import * as React from "react";
import Layout from "../components/layout";


const Index = ({ data }) => {
  return (
    <Layout pageTitle="KSEA NBM">
      <h1 className="text-2xl font-bold pb-6">Please login at <a href="https://ksea-nbm.tv">ksea-nbm.tv</a></h1>

      <div className="my-10">
        &nbsp;
      </div>
    </Layout>
  );
};


export default Index;
